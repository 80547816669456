.Account {
  width: 100%;
  max-width: 680px;
  text-align: center;
}

.Account .Forms {
  max-width: 600px;
  margin: auto;
}

.Account .Next {
  margin-top: 40px;
  margin-bottom: 50px;
}

.Account input.primary {
  background-color: #34b558;
  background: linear-gradient(to right, #34b558 0%, #34b57a 75%);
  border: none;
  border-radius: 25px;
  outline: none;
  height: 50px;
  padding: 0 25px;
  cursor: pointer;

  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #363032;
}

.Account input.primary {
  color: white;
}
