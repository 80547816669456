.ReviewCard {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  background-color: #c2ffdb;
  padding: 15px 25px;
  border-radius: 20px;
}

.ReviewCard img {
  width: 40px;
  height: 40px;
}

.ReviewCard .date {
  color: #939393;
}
