.ReviewCaroussel {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 80px;
}

.ReviewCaroussel .buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.ReviewCaroussel .buttons > div {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  background-color: #00ad54;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;
}
.ReviewCaroussel .buttons > div:nth-child(1) {
  margin-right: 10px;
}
.ReviewCaroussel .buttons > div > div {
  position: relative;
  top: 1px;
}

.ReviewCaroussel > div + div {
  border-top: 1px solid #64e3a1;
}

.ReviewCaroussel .fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ReviewCaroussel .fadeOut {
  animation-name: fadeOut;
  animation-duration: 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
