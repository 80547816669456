.Signup {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 1150px) {
  .Signup {
    grid-template-columns: minmax(320px, 792px) 1fr;
    grid-template-rows: min-content 1fr;
  }

  .Signup > div:nth-of-type(2) {
    order: 3;
  }

  .Signup > div:nth-of-type(3) {
    order: 2;
  }
}
