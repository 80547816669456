.ProgressBar {
  display: none;
}

@media (min-width: 700px) {
  .ProgressBar {
    padding: 38px 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .ProgressBar > div + div {
    margin-left: 44px;
  }

  .ProgressBar div {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    font-size: 21.3px;
    text-transform: uppercase;
    color: #404041;
    height: 32px;
    border-bottom: 5px solid #ededed;
  }

  .ProgressBar div.Active {
    border-bottom-color: #34b558;
  }
}
