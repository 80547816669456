.LatesInfo {
  background-color: #34b558;
  background: linear-gradient(to bottom, #34b558 0%,#34b57a 100%);
  padding: 25px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LatesInfo .Illustration {
  width: 320px;
  height: 302px;
  max-width: 100%;
}

.LatesInfo .Info {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18.67px;
  color: white;
  line-height: 37.3px;
  margin-bottom: 75px;
}

@media (min-width: 700px) {
  .LatesInfo {
    padding: 38px 50px;
  }
}
