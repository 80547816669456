.Page {
  padding: 25px;
  display: flex;
  justify-content: center;
  position: relative;
}

.Page h1 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  color: #404041;
  margin-top: 0;
  margin-bottom: 6px;
}

.Page h2 {
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #404041;
  margin-bottom: 6px;
}

.Page p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18.67px;
  color: #404041;
  margin-top: 0;
  margin-bottom: 40px;
}

.Page hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #cdcdcd;
  margin: 45px 0;
  padding: 0;
}

@media (min-width: 700px) {
  .Page {
    padding: 38px 50px;
  }

  .Page h1 {
    font-size: 40px;
  }
}

@media (min-width: 1150px) {
  .Page {
    min-width: 650px;
  }
}
